h2.terms{
    text-align: left;
    margin-left: 10px;
}

.imageContainerTerms{
    width: 50%;
    margin: 0 auto;
}


@media screen and (max-width: 600px) {
    .imageContainerTerms {
        display: none;
    }
  }
