a.error-404-p {
    color: #000000;
    font-weight: 400;
  }
  
  a.error-404-p:hover {
    color: #f3ad1b;
  }
  
  .index-error-404-column-01 {
    float: left;
    width: 48%;
    padding: 0 10px;
  }
  
  p.error-404-p {
    text-align: justify;
    font-size: 18px;
    line-height: 1.75;
    font-weight: 400;
    color: #101518;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  h2.error-404 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .page {
    height: 100vh;
}