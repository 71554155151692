.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}
  
  ul.navBar {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ffffff;
  }
  
  li.navBar {
    float: right;
  }
  li.navBarRight {
      float: left;
  }
  
  li.navBar {
    display: block;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
  }
  a.navBarLeft {
    display: block;
    color: #000000;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;

  }
  a.navBar {
    display: block;
    color: #000000;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }

  
  li.navBar a.navBar:hover:not(.active) {
    background-color: #ffffff;
  }

  h1.Header{
    text-align: center;
  }
  
