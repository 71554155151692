.image-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
  grid-gap: 16px; /* Adjust the gap as needed */
}

.image-item {
  width: 100%; /* Each image-item spans the full width of its column */
  box-sizing: border-box;
}

.image-item {
  cursor: pointer;
}
.video-item {
  width: 48%; /* Two images per row */
  margin-bottom: 10px;
  height: 600px;
  cursor: pointer;
}
img.image {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
img.modalImage{
  width: 30%;
}
@media (min-width: 1262px) {
  .video-item {
    height: 1000px; /* Two images per row */
  }
}
@media (max-width: 767px) {
  .image-item {
    width: 100%; /* One image per row on mobile */
  }
  .video-item {
    width: 100%; /* Two images per row */
  }
}

  .imageContainer{
    margin-top: 10px;    
    margin-bottom: 10px;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    color: #fff;
    padding: 10px;
    border-radius: 4px;
  }
  
  .modal-image {
    width: 35%;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .modal-text {
    font-size: 16px;
    color: black;
  }

  .modal-close {
    position: absolute;
    top: 75px;
    right: 10px;
    font-size: 24px;
    background-color: white;
    border: none;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    .image-gallery {
      display: grid;
      grid-template-columns: repeat(1, 1fr); /* Two columns with equal width */
      grid-gap: 16px; /* Adjust the gap as needed */
    }
    .modal-content {
      flex-direction: column;
      align-items: center;
    }
    
    .modal-image {
      margin-bottom: 20px;    
      width: 75%;
      height: 75%;
      margin-right: 0px;
    }
    .modal-text {
      background-color: black;
      color: white;
      padding: 10px;
    }
    .modal-close {
      position: absolute;
      top: 60px;
      right: 20px;
      font-size: 24px;
      background-color: white;
      border: none;
      cursor: pointer;
    }
    .modal-text-container {
      max-height: 200px;
      overflow-y: auto;
    }
  }

