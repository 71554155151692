.column{
    height: 100%
}
@media screen and (max-width: 600px) {
    .column{
        height: 125%
  }
}
.row-info {
    height: 100vh;
}
p.contact {
    text-align: center;
}

.imageContainerContact{
    width: 50%;
    margin: 0 auto;
}