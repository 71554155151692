* {
    box-sizing: border-box;
  }
  .imageContainer{
    width: 50%;
    margin: 0 auto;
}

.column {
    float: left;
    width: 50%;
    padding: 10px;
  }

  .row-about {
      height: 150vh;
  }

  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }

  h2.about{
      text-align: center;
  }
  p.about{
      text-align: justify;
      width: 100%;
      margin-left: 15px;
      margin-right: 15px;
  }
  @media screen and (max-width: 600px) {
    p.about{
      text-align: justify;
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
  }
  }
  .vertical-center {
    margin: 0;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

